import React from 'react'
import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { useProperty } from 'eilmer-mvvm/react'

import { useAuthContext } from '#context/AuthContext'
import { useConfig } from '#context/AppContext'
import Privileges from '#primitives/Privileges'

import DashboardViewModel from '#viewmodel/DashboardViewModel'
import HtmlEditor from '#components/misc/HtmlEditor'
import CodeEditor from '#components/misc/CodeEditor'

export default function Dashboard({ appViewModel }) {
  appViewModel.breadcrumb = 'Dashboard'
  const viewModel = DashboardViewModel.useInstance()
  const website = useProperty(viewModel, 'website')

  if (website) {
    return <HelpText viewModel={viewModel} website={website} />
  } else {
    return null
  }
}

function HelpText({ viewModel, website }) {
  const permissions = useProperty(useAuthContext(), 'permissions')
  const htmlDashboard = useConfig('htmlDash')
  const editing = useProperty(viewModel, 'editing')
  const helpText = useProperty(website, 'help_text')
  const [toggle, setToggle] = React.useState(false)
  const valueRef = React.useRef(helpText)

  const setHelpText = (value) => {
    valueRef.current = value
    setToggle((toggle) => !toggle)
  }

  const getHelpText = () => {
    return valueRef.current
  }

  const onSave = async () => {
    website.help_text = valueRef.current
    await viewModel.doSaveWebsite(website)
    viewModel.editing = false
  }

  if (!editing) {
    return (
      <Core.CCard style={{ display: 'flex', flex: '1 1 0%' }}>
        <Core.CCardBody
          style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        >
          {permissions?.hasPrivilege(Privileges.WEBSITE) && (
            <div>
              <a
                href="#"
                onClick={() => (viewModel.editing = true)}
                style={{ float: 'right' }}
              >
                <CIcon className="mr-2" title="Edit" name="cil-pencil" />
              </a>
            </div>
          )}
          <div
            style={{ flex: 1 }}
            dangerouslySetInnerHTML={{ __html: helpText }}
          />
        </Core.CCardBody>
      </Core.CCard>
    )
  } else {
    return (
      <Core.CCard style={{ display: 'flex', flex: '1 1 0%' }}>
        <Core.CCardBody>
          {htmlDashboard ? (
            <CodeEditor
              language="html"
              value={getHelpText()}
              onChange={(e) => setHelpText(e.target.value)}
            />
          ) : (
            <HtmlEditor
              value={getHelpText()}
              onChange={(e) => setHelpText(e.target.value)}
            />
          )}
        </Core.CCardBody>
        <Core.CCardFooter>
          <Core.CButton color="success" onClick={onSave}>
            Save
          </Core.CButton>
          &nbsp;
          <Core.CButton
            color="danger"
            onClick={() => (viewModel.editing = false)}
          >
            Cancel
          </Core.CButton>
        </Core.CCardFooter>
      </Core.CCard>
    )
  }
}

import TemplateItemTypesModel from '#model/TemplateItemTypesModel.js'
import { useApiClient } from '#context/ApiContext'
import { useNotifications } from '#context/NotificationContext'
import { useInstanceOf } from 'eilmer-mvvm/react'

export default class TemplateItemTypesViewModel {

  constructor(apiClient, notificationsProvider, templateId) {
    this.model = new TemplateItemTypesModel(apiClient)
    this.notifications = notificationsProvider
    this.templateId = Number(templateId)
    this.entity = new TemplateItemTypesModel.TemplateItemType()
    this.editing = false
    this.loadEntities()
  }

  async loadEntities() {
    if (this.templateId) {
      try {
        this.entities = await this.model.getTemplateItemTypes(this.templateId)
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  doEditEntity(entity) {
    const editingEntity = entity || {template_id: this.templateId}
    this.entity = new TemplateItemTypesModel.TemplateItemType(editingEntity)
    this.editing = true
  }

  doCancelEdit() {
    this.editing = false
  }

  async doSaveEntity(entity) {
    try {
      if (entity.isNew()) {
        const result = await this.model.createTemplateItemType(entity)
        this.editing = false
        this.loadEntities()
        return result
      } else {
        const result = await this.model.updateTemplateItemType(entity)
        this.editing = false
        this.loadEntities()
        return result
      }
    } catch (err) {
      console.error(err)
      this.notifications.error(err.message)
    }
  }

  async doDeleteEntity(entity) {
    if (await this.notifications.confirm('Are you sure you\'d like to delete this item type?')) {
      try {
        const result = await this.model.deleteTemplateItemType(entity)
        this.loadEntities()
        return result
      } catch (err) {
        console.error(err)
        this.notifications.error(err.message)
      }
    }
  }

  get fields() {
    return [
      { key: 'templ_item_type_id', label: 'ID', _style: { width: '10%'}, _classes: 'bg-light text-dark' },
      { key: 'item_type', label: 'Item Type', _style: { width: '50%'} },
      {
        key: 'actions',
        label: '',
        _style: { width: '90px' },
        filter: false
      }
    ]
  }

}

TemplateItemTypesViewModel.useInstance = function(templateId) {
  return useInstanceOf(TemplateItemTypesViewModel, useApiClient(), useNotifications(), templateId)
}

import React from 'react'
import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { useAppViewModel } from '#context/AppContext'
import { useMediaLibrary } from '#context/MediaLibraryContext'

import ItemsViewModel from '#viewmodel/ItemsViewModel'
import ItemTypesViewModel from '#viewmodel/ItemTypesViewModel'

import ItemTypes from '#utils/ItemTypes.js'

import * as Entities from '#components/widgets/Entities'
import HtmlEditor from '#components/misc/HtmlEditor'
import CodeEditor from '#components/misc/CodeEditor'

export function MediaItem({ innerRef, mimeType, required, preview, placeholder, value, onChange }) {
  // TODO: WHERE DOES THE WEBISTE COME FROM - OR WHERE IS IT SET?
  // DO THIS NOW - IT HAS TO BE IN THE AppViewModel
  const mediaLibrary = useMediaLibrary()
  const apiEndpoint = useAppViewModel().apiClient.endpoint
  const [ mediaObject, setMediaObject ] = React.useState()
  const previewSrc = apiEndpoint + mediaObject?.url

  const getMediaObject = async () => {
    const mediaObject = await mediaLibrary.selectMediaObject(mimeType)
    updateMediaObject(mediaObject)
    // Ok, ok, so: it'd be nice if the value was selected when you
    // open it but still
  }

  const updateMediaObject = (mediaObject) => {
    setMediaObject(mediaObject)
    onChange({target:{
      value: mediaObject?.id || null,
      mediaObject: mediaObject || null
    }})
  }

  React.useEffect(() => {
    if (value && value !== mediaObject?.id) {
      (async () => {
        const mediaObject = await mediaLibrary.getMediaObject(value)
        setMediaObject(mediaObject)
      })()
    } else if (!value) {
      setMediaObject(null)
    }
  }, [value])

  return (
    <>
      <Core.CInputGroup>
        <Core.CInput innerRef={innerRef} required={required} type="text" placeholder={placeholder || 'Choose Media...'} value={mediaObject?.name || ''} onChange={() => {}}/>
        <Core.CInputGroupAppend>
          <Core.CButton color="primary" onClick={getMediaObject}>Open Media Library</Core.CButton>
        </Core.CInputGroupAppend>
        {!required &&
          <Core.CInputGroupAppend>
            <Core.CButton color="danger" onClick={() => updateMediaObject(null)}>
              <CIcon name="cil-trash"/>
            </Core.CButton>
          </Core.CInputGroupAppend>
        }
      </Core.CInputGroup>
      {preview &&
        <div className="mt-2" style={{width:'100%',height:'200px',position:'relative'}}>
          {mediaObject?.url &&
            <img src={previewSrc} style={{width:'100%', height:'100%', objectFit: 'contain'}}/>
          }
        </div>
      }
    </>
  )
}

export function Html({ innerRef, required, placeholder, value, onChange }) {
  const plainValue = value?.replace(/<[\S]+?><\/[\S]+?>/gim, '')

  React.useEffect(() => {
    innerRef?.current?.dispatchEvent(new Event('change'))
  }, [plainValue])

  return (
    <>
        <input className="form-control" ref={innerRef} required={required} value={plainValue} style={{display:'none'}}/>
        <HtmlEditor value={value} onChange={onChange}/>
    </>
  )
}

export function Code({ innerRef, required, language, value, onChange }) {

  React.useEffect(() => {
    innerRef?.current?.dispatchEvent(new Event('change'))
  }, [value])

  return (
    <>
        <input className="form-control" ref={innerRef} required={required} value={value} style={{display:'none'}}/>
        <CodeEditor language={language} value={value} onChange={onChange}/>
    </>
  )
}

// TODO: Is there any way to enforce uniqueness?
export function Slug({ innerRef, required, placeholder, value, onChange }) {
  return (
    <Core.CInput innerRef={innerRef} required={required} value={value} onChange={onChange}/>
  )
}

export function File({ innerRef, required, value, onChange }) {
  const [ isRequired, setIsRequired ] = React.useState(required && !value?.name)

  React.useEffect(() => {
    setIsRequired(required && !value?.name)
  }, [required, value])

  const clearValue = () => {
    onChange({target:{files:null}})
  }

  return (
    <Core.CInputGroup>
      <div className="custom-file">
        <Core.CInputFile innerRef={innerRef} custom required={isRequired} onChange={onChange}/>
        <label className="custom-file-label">{value?.name || ''}</label>
      </div>
      {!required &&
        <Core.CInputGroupAppend>
          <button class="btn btn-secondary" type="button" style={{height:'calc(1.5em + 0.75rem + 2px)'}} onClick={clearValue}>
            <CIcon title="Delete" name="cil-trash"/>
          </button>
        </Core.CInputGroupAppend>
      }
    </Core.CInputGroup>
  )
}

export function Link({ innerRef, required, placeholder, value, onChange }) {
  const setToggle = React.useState(true)[1]
  const internalValue = value || {}
  internalValue.linkType = internalValue.linkType || 'page'
  internalValue.url = internalValue.url || ''

  const linkTypeChanged = (e) => {
    internalValue.linkType = e.target.value
    handleChanged();
  }

  const urlChanged = (e) => {
    internalValue.url = e.target.value
    handleChanged();
  }

  const pageChanged = (e) => {
    internalValue.url = ''
    internalValue.page = e.target.value
    handleChanged();
  }

  const handleChanged = () => {
    if (typeof onChange === 'function') {
      onChange({target:{value:{...internalValue}}})
    } else {
      setToggle((toggle) => !toggle)
    }
  }

  // TODO: Allow a content block *on* the page to be selected...
  // see commented section below...

  return (
    <Core.CInputGroup>
      <Core.CInputGroupPrepend>
        <Core.CSelect custom value={internalValue.linkType} onChange={linkTypeChanged}>
          <option value="page">Internal Page</option>
          <option value="url">External URL</option>
        </Core.CSelect>
      </Core.CInputGroupPrepend>
      {internalValue.linkType === 'page' ? 
        <>
          <Item innerRef={innerRef} itemTypeId={ItemTypes.PAGE} required={required} value={internalValue.page} onChange={pageChanged}/>
          {/*
          <Core.CInputGroupAppend>
            <Item innerRef={innerRef} itemTypeId={ItemTypes.CONTENT_BLOCK} parentItemId={internalValue.page} required={required} value={null} onChange={() => {}}/>
          </Core.CInputGroupAppend>
          */}
        </>
      :
        <Core.CInput type="url" required={required} placeholder={placeholder || 'https://'} value={internalValue.url} onChange={urlChanged}/>
      }
    </Core.CInputGroup>
  )
}

export function Item({ innerRef, required, itemTypeId, parentItemId, value, onChange }) {
  const viewModel = ItemsViewModel.useInstance(itemTypeId, parentItemId)
  return (
    <Entities.EntityDropdown innerRef={innerRef} required={required} value={value} onChange={onChange} entityViewModel={viewModel} property='items'/>
  )
}

export function ItemType({ innerRef, required, itemClassId, value, onChange }) {
  const viewModel = ItemTypesViewModel.useInstance(itemClassId)
  return (
    <Entities.EntityDropdown innerRef={innerRef} required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}
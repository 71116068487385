import React from 'react'
import { useProperty } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { useAuthContext } from '#context/AuthContext'
import { useAppViewModel, useConfigField } from '#context/AppContext'

import ItemClasses from '#primitives/ItemClasses'
import Privileges from '#primitives/Privileges'

export default function AdminSidebar({ mode }) {
  const authViewModel = useAuthContext()
  const appViewModel = useAppViewModel()
  const [showing, setShowing] = React.useState(true)
  const itemTypes = useProperty(appViewModel, 'itemTypes')?.filter(
    (each) => !each.parent_type_id
  )
  const contentItemTypes = itemTypes?.filter(
    (each) => each.item_class_id === ItemClasses.CONTENT
  )
  const dataItemTypes = itemTypes?.filter(
    (each) => each.item_class_id === ItemClasses.DATA
  )
  const formItemTypes = itemTypes?.filter(
    (each) => each.item_class_id === ItemClasses.FORM
  )
  const permissions = useProperty(authViewModel, 'permissions')
  const apiEndpoint = appViewModel.apiClient.endpoint
  const logoField = useConfigField('logo')
  const navitems = []

  // TODO: How about permission for the Media Library and Menus?
  if (permissions?.hasPrivilege(Privileges.WEBSITE)) {
    navitems.push(
      <>
        <Core.CSidebarNavTitle>Website</Core.CSidebarNavTitle>
        <Core.CSidebarNavItem icon="cil-list" name="Menus" to="/menus" />
        <Core.CSidebarNavItem icon="cil-image" name="Media" to="/media" />
        <Core.CSidebarNavItem
          icon="cil-settings"
          name="Settings"
          to="/settings"
        />
      </>
    )
  }

  if (
    (permissions?.hasPrivilege(Privileges.CONTENT) &&
      contentItemTypes?.length) ||
    (permissions?.hasPrivilege(Privileges.DATA) && dataItemTypes?.length)
  ) {
    const editableItemTypes = itemTypes.filter((each) =>
      [ItemClasses.CONTENT, ItemClasses.DATA].includes(each.item_class_id)
    )
    navitems.push(
      <>
        <Core.CSidebarNavTitle>Items</Core.CSidebarNavTitle>
      </>
    )
    editableItemTypes?.map((itemType) => {
      const isContent = itemType.item_class_id === ItemClasses.CONTENT
      const privilege = isContent ? Privileges.CONTENT : Privileges.DATA
      if (permissions?.hasPrivilege(privilege, itemType)) {
        navitems.push(
          <Core.CSidebarNavItem
            key={itemType.item_type_id}
            icon="cil-library"
            name={itemType.name}
            to={`/items/${itemType.item_type_id}`}
          />
        )
      }
    })
  }

  if (permissions?.hasPrivilege(Privileges.FORMS)) {
    const allowedFormItemTypes = formItemTypes?.filter((itemType) =>
      permissions?.hasPrivilege(Privileges.FORMS, itemType)
    )
    if (allowedFormItemTypes?.length) {
      navitems.push(
        <>
          <Core.CSidebarNavTitle>Forms</Core.CSidebarNavTitle>
        </>
      )
      allowedFormItemTypes?.map((itemType) => {
        navitems.push(
          <Core.CSidebarNavItem
            key={itemType.item_type_id}
            icon="cil-library"
            name={itemType.name}
            to={`/items/${itemType.item_type_id}`}
          />
        )
      })
    }
  }

  if (
    permissions?.hasPrivilege(Privileges.USERS) ||
    permissions?.hasPrivilege(Privileges.ROLES)
  ) {
    navitems.push(
      <>
        <Core.CSidebarNavTitle>Users</Core.CSidebarNavTitle>
      </>
    )
    if (permissions?.hasPrivilege(Privileges.USERS)) {
      navitems.push(
        <Core.CSidebarNavItem icon="cil-people" name="Users" to="/users" />
      )
    }
    if (permissions?.hasPrivilege(Privileges.ROLES)) {
      navitems.push(
        <Core.CSidebarNavItem
          icon="cil-people"
          name="User Roles"
          to="/user-roles"
        />
      )
    }
  }

  if (permissions?.hasPrivilege(Privileges.APPLICATION)) {
    navitems.push(
      <>
        <Core.CSidebarNavTitle>Dev</Core.CSidebarNavTitle>
        <Core.CSidebarNavItem
          icon="cil-settings"
          name="Config"
          to="/developer/config"
        />
        <Core.CSidebarNavItem
          icon="cil-browser"
          name="Websites"
          to="/developer/websites"
        />
        <Core.CSidebarNavItem
          icon="cil-storage"
          name="Field Types"
          to="/developer/field-types"
        />
        <Core.CSidebarNavItem
          icon="cil-fork"
          name="Item Classes"
          to="/developer/item-classes"
        />
        <Core.CSidebarNavItem
          icon="cil-sitemap"
          name="Item Types"
          to="/developer/item-types"
        />
        <Core.CSidebarNavItem
          icon="cil-storage"
          name="User Fields"
          to="/developer/user-fields"
        />
        <Core.CSidebarNavItem
          icon="cil-border-all"
          name="Content Blocks"
          to="/developer/content-blocks"
        />
        <Core.CSidebarNavItem
          icon="cil-code"
          name="Templates"
          to="/developer/templates"
        />
        <Core.CSidebarNavDropdown icon="cil-bolt" name="Primitives">
          <Core.CSidebarNavItem
            name="Types"
            to="/developer/types"
          />
          <Core.CSidebarNavItem
            name="Blocks"
            to="/developer/blocks"
          />
          <Core.CSidebarNavItem
            name="Privileges"
            to="/developer/privileges"
          />
          <Core.CSidebarNavItem
            name="Validators"
            to="/developer/validators"
          />
          <Core.CSidebarNavItem
            name="Constraints"
            to="/developer/constraints"
          />
          <Core.CSidebarNavItem
            name="Statuses"
            to="/developer/statuses"
          />
        </Core.CSidebarNavDropdown>
      </>
    )
  }

  if (mode === 'manage') {
    return (
      <Core.CSidebar show={showing} unfoldable onShowChange={setShowing}>
        <Core.CSidebarBrand style={{ background: 'black', color: 'white' }}>
          {logoField?.json_value !== null ? (
            <img
              src={`${apiEndpoint}/filevalue/${logoField?.value_id}`}
              style={{ width: '100%' }}
            />
          ) : typeof logoField === 'undefined' ? null : (
            <span>
              Nano<strong>CMS</strong>
            </span>
          )}
        </Core.CSidebarBrand>
        <Core.CSidebarNav>
          {/*
          <Core.CSidebarNavTitle>Managing <small>www.malmesbury-live-arts.org.uk</small></Core.CSidebarNavTitle>
          */}
          <Core.CSidebarNavItem
            icon="cil-speedometer"
            name="Dashboard"
            to="/"
          />

          {navitems}
        </Core.CSidebarNav>
        {/*
        <Core.CSidebarMinimizer className="c-d-md-down-none"/>
        */}
      </Core.CSidebar>
    )
  }
}

import TreeQLModel from './TreeQLModel'

export default class TemplatesModel extends TreeQLModel {
  constructor(apiClient) {
    super(apiClient)
  }

  async getTemplates() {
    return (await super.list('templates')).map((each) => {
      return new TemplatesModel.Template(each)
    })
  }

  async createTemplate(template) {
    const entity = new TemplatesModel.Template(template)
    return await super.create('templates', entity)
  }

  async updateTemplate(template) {
    const entity = new TemplatesModel.Template(template, true)
    return await super.update('templates', entity.getPk(), entity)
  }

  async deleteTemplate(template) {
    const entity = new TemplatesModel.Template(template)
    return await super.delete('templates', entity.getPk())
  }
}

TemplatesModel.Template = class extends TreeQLModel.Entity {
  constructor(fields, forUpdate) {
    super(fields, 'template_id', forUpdate)
  }
}

import React from 'react'
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-twig';
import 'prismjs/themes/prism.css';

export default function CodeEditor({ language, value, onChange }) {

  const changeHandler = (v) => {
    onChange({target:{value:v}})
  }

  const highlightHandler = (code) => {
    return highlight(code, languages[language], language)
  }

  return (
    <Editor
      value={value || ''}
      onValueChange={changeHandler}
      className="form-control"
      highlight={highlightHandler}
      padding={10}
      style={{
        fontFamily: 'monospace',
        fontSize: 12,
        height: 'auto',
      }}
    />
  )
}
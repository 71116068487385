import React from 'react'
import { useParams } from 'react-router-dom'
import { useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import ItemTypesViewModel from '#viewmodel/ItemTypesViewModel'
import TemplateItemTypesViewModel from '#viewmodel/TemplateItemTypesViewModel'

import * as Forms from '#components/widgets/Forms'
import * as Entities from '#components/widgets/Entities'

export default function TemplateItemTypes({ appViewModel }) {
  const { template_id } = useParams()
  appViewModel.breadcrumb = 'Developer / Template Item Types'
  const viewModel = TemplateItemTypesViewModel.useInstance(template_id)

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  return (
    <Core.CCard style={{ display: 'flex', flex: '1 1 0%' }}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton
            color="primary"
            size="sm"
            onClick={() => handleAddEntity()}
          >
            <CIcon name="cil-library-add" />
            &nbsp;Add Item Type
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <EntityDataTable viewModel={viewModel} />
      </Core.CCardBody>
      <EditEntityModal entityName="Template Item Type" viewModel={viewModel} />
    </Core.CCard>
  )
}

function EntityDataTable({ viewModel }) {
  const entities = useProperty(viewModel, 'entities')

  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  return (
    <Entities.DataTable
      entities={entities}
      fields={viewModel.fields}
      scopedSlots={{
        'item_type':
          (entity)=>(
            <td>
              {entity.getItemType().name}
            </td>
          ),
        actions: (entity) => (
          <td style={{ textAlign: 'right' }}>
            <a href="#" onClick={() => handleEditEntity(entity)}>
              <CIcon className="mr-2" title="Edit" name="cil-pencil" />
            </a>
            <a href="#" onClick={() => handleDeleteEntity(entity)}>
              <CIcon title="Delete" name="cil-trash" />
            </a>
          </td>
        ),
      }}
    />
  )
}

function EditEntityModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const [ itemTypeId, setItemTypeId ] = useBinding(viewModel.entity, 'item_type_id')

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  return (
    <Core.CModal centered closeOnBackdrop={false} show={open} size="lg">
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Item Type</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <ItemTypeDropdown required={true} value={itemTypeId} onChange={e => setItemTypeId(e.target.value)}/>
            </Core.CCol>
          </Core.CFormGroup>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">
            Cancel
          </Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">
            Save
          </Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}

function ItemTypeDropdown({ required, value, onChange }) {
  const viewModel = ItemTypesViewModel.useInstance()
  return (
    <Entities.EntityDropdown required={required} value={value} onChange={onChange} entityViewModel={viewModel}/>
  )
}

import TreeQLModel from './TreeQLModel'

export default class TemplateItemTypesModel extends TreeQLModel {
  constructor(apiClient) {
    super(apiClient)
  }

  async getTemplateItemTypes(templateId) {
    const options = {filter: `template_id,eq,${templateId}`, join:['item_types']}
    return (await super.list('template_item_types', options)).map((each) => {
      each.item_types = [each.item_type_id]
      each.item_type_id = each.item_types[0]?.item_type_id
      return new TemplateItemTypesModel.TemplateItemType(each)
    })
  }

  async createTemplateItemType(templateItemType) {
    const entity = new TemplateItemTypesModel.TemplateItemType(templateItemType)
    return await super.create('template_item_types', entity)
  }

  async updateTemplateItemType(templateItemType) {
    const entity = new TemplateItemTypesModel.TemplateItemType(templateItemType, true)
    return await super.update('template_item_types', entity.getPk(), entity)
  }

  async deleteTemplateItemType(templateItemType) {
    const entity = new TemplateItemTypesModel.TemplateItemType(templateItemType)
    return await super.delete('template_item_types', entity.getPk())
  }
}

TemplateItemTypesModel.TemplateItemType = class extends TreeQLModel.Entity {
  constructor(fields, forUpdate) {
    super(fields, 'templ_item_type_id', forUpdate)
  }

  getItemType() {
    return this.item_types ? this.item_types[0] : undefined
  }

}

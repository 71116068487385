import React from 'react'
import { Link } from 'react-router-dom'
import { useProperty, useBinding } from 'eilmer-mvvm/react'

import * as Core from '@coreui/react'
import CIcon from '@coreui/icons-react'

import TemplatesViewModel from '#viewmodel/TemplatesViewModel'

import * as Forms from '#components/widgets/Forms'
import * as Inputs from '#components/widgets/Inputs'
import * as Entities from '#components/widgets/Entities'

export default function Templates({ appViewModel }) {
  appViewModel.breadcrumb = 'Developer / Templates'
  const viewModel = TemplatesViewModel.useInstance()

  const handleAddEntity = () => {
    viewModel.doEditEntity(null)
  }

  return (
    <Core.CCard style={{ display: 'flex', flex: '1 1 0%' }}>
      <Core.CCardHeader>
        <div className="card-header-actions">
          <Core.CButton
            color="primary"
            size="sm"
            onClick={() => handleAddEntity()}
          >
            <CIcon name="cil-library-add" />
            &nbsp;Add Template
          </Core.CButton>
        </div>
      </Core.CCardHeader>
      <Core.CCardBody>
        <EntityDataTable viewModel={viewModel} />
      </Core.CCardBody>
      <EditEntityModal entityName="Template" viewModel={viewModel} />
    </Core.CCard>
  )
}

function EntityDataTable({ viewModel }) {
  const entities = useProperty(viewModel, 'entities')

  const handleEditEntity = (entity) => {
    viewModel.doEditEntity(entity)
  }

  const handleDeleteEntity = (entity) => {
    viewModel.doDeleteEntity(entity)
  }

  return (
    <Entities.DataTable
      entities={entities}
      fields={viewModel.fields}
      scopedSlots={{
        actions: (entity) => (
          <td style={{ textAlign: 'right' }}>
            <a href="#" onClick={() => handleEditEntity(entity)}>
              <CIcon className="mr-2" title="Edit" name="cil-pencil" />
            </a>
            <Link to={`/developer/template-item-types/${entity.template_id}`}>
              <CIcon className="mr-2" title="Item Types" name="cil-sitemap" />
            </Link>
            <a href="#" onClick={() => handleDeleteEntity(entity)}>
              <CIcon title="Delete" name="cil-trash" />
            </a>
          </td>
        ),
      }}
    />
  )
}

function EditEntityModal({ viewModel, entityName }) {
  const open = useProperty(viewModel, 'editing')
  const [name, setName] = useBinding(viewModel.entity, 'name')
  const [description, setDescription] = useBinding(
    viewModel.entity,
    'description'
  )
  const [template, setTemplate] = useBinding(viewModel.entity, 'template')

  const handleCancel = () => {
    viewModel.doCancelEdit()
  }

  const handleSave = () => {
    viewModel.doSaveEntity(viewModel.entity)
  }

  return (
    <Core.CModal centered closeOnBackdrop={false} show={open} size="lg">
      <Forms.ValidatingForm onSubmit={handleSave} onReset={handleCancel}>
        <Core.CModalHeader>Add/Edit {entityName}</Core.CModalHeader>
        <Core.CModalBody>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Name</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput
                    innerRef={ref}
                    required
                    type="text"
                    value={name || ''}
                    onChange={(e) => setName(e.target.value)}
                  />
                )}
              </Forms.ValidatedFormControl>
              <small>
                <em>For internal use to identify this {entityName}</em>
              </small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Description</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Forms.ValidatedFormControl>
                {({ ref }) => (
                  <Core.CInput
                    innerRef={ref}
                    required
                    type="text"
                    value={description || ''}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                )}
              </Forms.ValidatedFormControl>
              <small>
                <em>For internal use to describe this {entityName}</em>
              </small>
            </Core.CCol>
          </Core.CFormGroup>
          <Core.CFormGroup row>
            <Core.CCol md="3">
              <Core.CLabel>Template</Core.CLabel>
            </Core.CCol>
            <Core.CCol xs="12" md="9">
              <Inputs.Code
                language="twig"
                required={true}
                value={template}
                onChange={(e) => setTemplate(e.target.value)}
              />
            </Core.CCol>
          </Core.CFormGroup>
        </Core.CModalBody>
        <Core.CModalFooter>
          <Core.CButton color="secondary" type="reset">
            Cancel
          </Core.CButton>
          &nbsp;
          <Core.CButton color="primary" type="submit">
            Save
          </Core.CButton>
        </Core.CModalFooter>
      </Forms.ValidatingForm>
    </Core.CModal>
  )
}
